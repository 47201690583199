<template>
  <validation-observer ref="formBank">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="9"
            >
              <b-row
                class="my-1"
                align-v="baseline"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="bank"
                  >Bank</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Bank"
                    rules="required"
                  >
                    <v-select
                      id="bank"
                      v-model="models.bankId"
                      label="name"
                      placeholder="- Pilih Bank -"
                      :state="errors.length > 0 ? false:null"
                      :options="masters.banks"
                      :reduce="option => option.id"
                      @search="debounceSearch"
                    >
                      <template #no-options="{ search, searching, loading }">
                        Tidak ditemukan hasil pencarian
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="account number"
                  >Nomor Rekening</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor rekening"
                    rules="required|numeric"
                  >
                    <b-form-input
                      id="account number"
                      v-model="models.accountNumber"
                      type="number"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3">
                  <label
                    class="h5"
                    for="name"
                  >Nama</label>
                </b-col>
                <b-col sm="8">
                  <validation-provider
                    #default="{ errors }"
                    name="Nama"
                    rules="required"
                  >
                    <b-form-input
                      id="name"
                      v-model="models.accountHolder"
                      type="text"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
                align-v="center"
              >
                <b-col sm="3" />
                <b-col sm="8">
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="models.isDefault"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Jadikan sebagai akun bank utama
                  </b-form-checkbox>
                </b-col>
              </b-row>

            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end">

            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({ name: 'profile', params: { defaultActive: 'bank' } })"
            >
              Batal
            </b-button>
            <b-button
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import vSelect from 'vue-select'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'

export default {
  name: 'FormBank',
  metaInfo: {
    title: 'Form Owner',
  },
  components: {
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        bankId: null,
        accountHolder: '',
        accountNumber: '',
        isDefault: false,
      },
      masters: {
        banks: [],
      },
      required,
      numeric,
    }
  },
  created() {
    this.$axios.get(api.list_bank).then(res => {
      this.masters.banks = res.data.data.rows
      this.getById()
    })
  },
  methods: {
    getById() {
      if (this.$route.params.id) {
        this.$axios.get(`${api.bankAccount}/${this.$route.params.id}`)
          .then(res => {
            const { data } = res.data
            this.models.bankId = data.bankId
            this.models.accountHolder = data.accountHolder
            this.models.accountNumber = data.accountNumber
            this.models.isDefault = data.isDefault

            const bankObj = this.masters.banks.find(bank => Number(bank.id) === Number(data.bank.id))
            if (!bankObj) {
              this.masters.banks = [data.bank, ...this.masters.banks]
            }
          }).catch(() => this.$router.push({
            name: 'page404',
          }))
      }
    },
    debounceSearch(query) {
      if (query !== '') {
        clearTimeout(this.debounce)
        this.debounce = setTimeout(async () => {
          const response = await this.handleSearchTag(query)
          this.masters.banks = [...response]
        }, 500)
      }
    },
    async handleSearchTag(query) {
      const res = await this.$axios.get(`${api.list_bank}?search=${query}`)
      return res.data.data.rows
    },
    save() {
      this.$refs.formBank.validate().then(async success => {
        if (success) {
          let request = ''
          let message = 'create'

          if (!this.$route.params.id) {
            request = this.$axios.post(api.bankAccount, this.models)
          } else {
            message = 'update'
            request = this.$axios.put(`${api.bankAccount}/${this.$route.params.id}`, this.models)
          }
          request
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Success ${message} bank account`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push({ name: 'profile', params: { defaultActive: 'bank' } })
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
